.forgot-password-btn{
    text-decoration: none;
    text-transform: none;
    color: #1976D2;
    font-weight: 600;
}

/* .css-binzgt{
    margin-top: 0;
} */
