.profile-image{
    width: 200px;
    border-radius: 50%;
}

.contain-profile-section{
    display: flex;
    justify-content: space-between;
    margin: 0 10%;
}

.profile-name{
    font-size: 1.5rem;
    text-transform: capitalize;
}

.profile-email{
    font-size: 1.3rem;
    font-style: italic;
    color: navy;
}

.contain-each-detail{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.each-entry-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem 0;
}

.each-entry-cover{
    display: flex;
}

.update-password-container{
    display: flex;
    margin: 0 10%;
}

.back-btn-container{
    display: flex;
    margin: 0 10%;
}
.fa-arrow-left{
    margin-right: 5px;
    font-size: 16px;
}

.fa-edit{
    font-size: 20px;
    margin-left: 10px;
    cursor: pointer;
}
 
.change-password-btn{
    background: #F47954 !important;
}


@media only screen and (max-device-width:768px){
    .contain-profile-section{
        flex-direction: column-reverse;
    }

    .each-entry-cover{
        display: block;
    }
    .contain-each-detail{
        margin: 1rem 0;
    }
    h2{
        text-align: center !important;
        margin: 0;
    }
    .update-password-container{
        display: block;
    }
}