.App {
  text-align: center;
  width: 100%;
  font-family: 'Poppins', sans-serif !important;
  background: #F5F6F8;
  /* background: #7F8182EB; */
  height: 100vh;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

h2{
  font-size: 2rem;
  font-weight: 600;
  text-align: left;
}

/* MUI STYLES OVERWRITTEN */

.css-1fu7jd5-MuiButtonBase-root-MuiButton-root:hover{
  background: #F47954;
}

.css-nzinn5-MuiContainer-root{
  max-width: 510px !important;
}

circle, 
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed, 
.css-1i5daz-MuiButtonBase-root-MuiButton-root:active{
  color:#F47954 !important
}
.css-4im44a-MuiButtonBase-root-MuiButton-root:hover{
  background:#F47954 !important
}

.css-1i5daz-MuiButtonBase-root-MuiButton-root:hover,
.css-unx9gr-MuiButtonBase-root-MuiButton-root:hover{
  background: white !important;
  box-shadow: none !important;
}

.jss69{
  width: 100% !important;
}

#collapsible-trigger-1640929890542{
  color:#F47954 !important;
  font-weight: bold;
}

.real-time-template-scrolling::-webkit-scrollbar{
  width: 5px;
}

.real-time-template-scrolling::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
.real-time-template-scrolling::-webkit-scrollbar-thumb {
  background: #F47954;
  border-radius: 5px; 
}